import React from "react";
import styled, {ThemeProvider, createGlobalStyle} from "styled-components";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";

import Theme from "../theme/";
import hexToRGBA from "../utils/hexToRGBA";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    padding: 0
  }

  html {
    font-family: "Roboto", sans-serif;
    font-size: 100%;
  }
`;

const Page = styled.main`
  padding: 0 32px;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
`;

const Header = styled.nav`
  padding: 32px;
  width: 100%;
`;

const Footer = styled.div`
  background-color: ${props => props.theme.color.primary.main};
  padding: 48px 32px;
`;

const PageLink = styled(Link)`
  ${props => props.theme.typography.body2};
  color: ${props => hexToRGBA(props.theme.color.secondary.light, 0.6)};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const MailTo = styled.a.attrs({href: "mailto:coachqapp@gmail.com"})`
  ${props => props.theme.typography.body2};
  color: ${props => hexToRGBA(props.theme.color.secondary.light, 0.6)};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Sections = styled.div`
  display: flex;
`;

const Section = styled.div``;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    margin-bottom: 0.25rem;
  }
`;

const SectionHeader = styled.div`
  ${props => props.theme.typography.h6};
  margin-bottom: 0.5rem;
`;

const CopyRight = styled.div`
  ${props => props.theme.typography.body2};
  color: ${props => hexToRGBA(props.theme.color.secondary.light, 0.6)};
  margin-top: 1.5rem;
`;

const Layout = (props: React.PropsWithChildren<{}>) => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Header>
        <Content>
          <Link to="/">
            <StaticImage
              placeholder="none"
              src="../images/web-logo.png"
              alt="coachq"
            />
          </Link>
        </Content>
      </Header>
      <Page>
        <Content>{props.children}</Content>
      </Page>
      <Footer>
        <Content>
          <Sections>
            <Section>
              <SectionHeader>coachq</SectionHeader>
              <List>
                <li>
                  <MailTo>Contact Us</MailTo>
                </li>
                <li>
                  <PageLink to="/privacy">Privacy Policy</PageLink>
                </li>
              </List>
            </Section>
          </Sections>
          <CopyRight>Copyright © 2021 Michal Dedys</CopyRight>
        </Content>
      </Footer>
    </ThemeProvider>
  );
};

export default Layout;
