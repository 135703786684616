import {CSSObject} from "styled-components";
import {Properties} from "csstype";

export type Color = {
  dark: string;
  main: string;
  light: string;
  contrast: string;
};

export type Palette = {
  background: {default: string};
  common: {black: string; white: string};
  primary: Color;
  secondary: Color;
  textPrimary: string;
  textSecondary: string;
  textDisabled: string;
  textHint: string;
};

export interface Font extends CSSObject {}

export type Typography = {
  h1: Font;
  h2: Font;
  h3: Font;
  h4: Font;
  h5: Font;
  h6: Font;
  subtitle1: Font;
  subtitle2: Font;
  body1: Font;
  body2: Font;
  button: Font;
  caption: Font;
  overline: Font;
};

export interface Theme {
  color: Palette;
  typography: Typography;
}

const theme: Theme = {
  color: {
    background: {default: "#ffffff"},
    common: {black: "#000", white: "#fff"},
    primary: {
      dark: "#B28B00",
      main: "#FFC700",
      light: "#FFD233",
      contrast: "#rgba(0,0,0, 0.87)",
    },
    secondary: {
      dark: "#000000",
      main: "#000000",
      light: "#333333",
      contrast: "#FFFFFF",
    },
    textPrimary: "rgba(0,0,0, 0.87)",
    textSecondary: "rgba(0,0,0, 0.54)",
    textDisabled: "rgba(0,0,0, 0.38)",
    textHint: "rgba(0,0,0, 0.38)",
  },
  typography: {
    h1: {fontSize: "6rem", fontWeight: 300, lineHeight: "112px"},
    h2: {fontSize: "3.75rem", fontWeight: 300, lineHeight: "72px"},
    h3: {fontSize: "3rem", fontWeight: 400, lineHeight: "56px"},
    h4: {fontSize: "2.125rem", fontWeight: 400, lineHeight: "42px"},
    h5: {fontSize: "1.5rem", fontWeight: 400, lineHeight: "32px"},
    h6: {fontSize: "1.25rem", fontWeight: 500, lineHeight: "32px"},
    subtitle1: {fontSize: "1rem", fontWeight: 400, lineHeight: "28px"},
    subtitle2: {fontSize: "0.875rem", fontWeight: 500, lineHeight: "22px"},
    body1: {fontSize: "1rem", fontWeight: 400, lineHeight: "24px"},
    body2: {fontSize: "0.875rem", fontWeight: 400, lineHeight: "20px"},
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: "24.5px",
      textTransform: "uppercase",
      letterSpacing: 0.39998,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "19.9px",
      letterSpacing: 0.4,
    },
    overline: {
      fontSize: "6rem",
      fontWeight: 400,
      lineHeight: "31.9px",
      textTransform: "uppercase",
    },
  },
};

export default theme;
